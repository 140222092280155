import React from 'react';
import OcassionenPage from '../components/OcassionenPage/ocassionenPage';
import Layout from '../components/layout';
import SEO from '../components/SEO/SEO';
import { Helmet } from 'react-helmet';

const Ocassionen = () => {
  return (
    <Layout>
      <SEO
        title='Occasionen'
        description='Entdecken Sie die Occasion Autos und Fahrzeuge online oder bei Bowman Automobile AG. Jetzt unverbindliche Probefahrt buchen.'
      />
      <Helmet>
        <script src='https://www.autoscout24.ch/MVC/Content/as24-hci-desktop/js/e.min.js' />
      </Helmet>
      <OcassionenPage />
    </Layout>
  );
};

export default Ocassionen;
