import React from 'react';
import * as ocassionenPageStyles from './ocassionenPage.module.scss';
import useScript from '../../config/utils/hook';

const OcassionenPage = () => {
  useScript(
    'https://www.autoscout24.ch/MVC/Content/as24-hci-desktop/js/e.min.js'
  );

  return (
    <>
      <div className={ocassionenPageStyles.ocassionenPageContainer}>
        <h1 className='text-center mt-4 pt-2'>
          <span className='yellow'>Occasionen</span>
        </h1>
        <div
          data-embedded-src='https://www.autoscout24.ch/de/hci/list?design=3869&filter=8812'
          className='embedded-content-area'
        ></div>
      </div>
    </>
  );
};

export default OcassionenPage;
